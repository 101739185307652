import abmeldegrundListStore from '@/modules/abmeldegrund/abmeldegrund-list-store';
import abmeldegrundFormStore from '@/modules/abmeldegrund/abmeldegrund-form-store';
import abmeldegrundDestroyStore from '@/modules/abmeldegrund/abmeldegrund-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: abmeldegrundDestroyStore,
    form: abmeldegrundFormStore,
    list: abmeldegrundListStore,
  },
};
