import authAxios from '@/shared/axios/auth-axios';
export class MitarbeiterService {
  static async update(id, data, reActivationStarted) {
    const body = {
      id,
      data,
      reActivationStarted,
    };
    const response = await authAxios.put(`/mitarbeiter/${id}`, body);
    return response.data;
  }
  static async updateBetriebsumlage(values) {
    const body = {
      data: values,
    };
    const response = await authAxios.post(`/mitarbeiter/betriebsumlage`, body);
    return response.data;
  }
  static async destroyAll(ids) {
    const params = {
      ids,
    };
    const response = await authAxios.delete(`/mitarbeiter`, {
      params,
    });
    return response.data;
  }
  static async abmelden(id, data) {
    const body = {
      id,
      data,
    };
    const response = await authAxios.put(`/mitarbeiter/abmelden/${id}`, body);
    return response.data;
  }
  static async create(data) {
    const body = {
      data,
    };
    const response = await authAxios.post(`/mitarbeiter`, body);
    return response.data;
  }
  static async import(values, importHash) {
    const body = {
      data: values,
      importHash,
    };
    const response = await authAxios.post(`/mitarbeiter/import`, body);
    return response.data;
  }
  static async importSalaryAdjustment(values, importHash) {
    const body = {
      data: values,
      importHash,
    };
    const response = await authAxios.post(
      `/mitarbeiter/import/salary-adjustment`,
      body,
    );
    return response.data;
  }
  static async find(id) {
    const response = await authAxios.get(`/mitarbeiter/${id}`);
    return response.data;
  }
  static async list(filter, orderBy, limit, offset, isExport, isExportWinline) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
      isExport,
      isExportWinline,
    };
    const response = await authAxios.get(`/mitarbeiter`, {
      params,
    });
    return response.data;
  }
  static async listHistory(id, orderBy, limit, offset) {
    const params = {
      id,
      orderBy,
      limit,
      offset,
    };
    const response = await authAxios.get(`/mitarbeiter/history`, {
      params,
    });
    return response.data;
  }
  static async listAutocomplete(limit, query, parentId, filter) {
    const params = {
      limit,
      query,
      parentId,
      filter,
    };
    const response = await authAxios.get(`/mitarbeiter/autocomplete`, {
      params,
    });
    return response.data;
  }
  static async createNotiz(data) {
    const body = {
      data,
    };
    const response = await authAxios.post(`/mitarbeiter/notiz`, body);
    return response.data;
  }
  static async listNotiz(mitarbeiterId) {
    const response = await authAxios.get(`/mitarbeiter/notiz/${mitarbeiterId}`);
    return response.data;
  }
  static async export(filter, orderBy, limit, offset, isExport) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
      isExport,
    };
    const response = await authAxios.get(`/mitarbeiter/export`, {
      params,
    });
    return response.data;
  }
  static async findHistory(id) {
    const response = await authAxios.get(`/mitarbeiter/history/${id}`);
    return response.data;
  }
  static async getAnstellung(id) {
    const response = await authAxios.get(`/mitarbeiter/anstellung${id}`);
    return response.data;
  }
}
