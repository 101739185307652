import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeField from '@/shared/fields/date-time-field';
import DateField from '@/shared/fields/date-field';
import StringField from '@/shared/fields/string-field';
import EnumeratorField from '@/shared/fields/enumerator-field';
import DecimalField from '@/shared/fields/decimal-field';
import IntegerField from '@/shared/fields/integer-field';
import FilesField from '@/shared/fields/files-field';
import { KostenstelleField } from '@/modules/kostenstelle/kostenstelle-field';
import { VerwendungKostenstelleField } from '@/modules/verwendung-kostenstelle/verwendung-kostenstelle-field';
import { StaatsbuergerschaftField } from '@/modules/staatsbuergerschaft/staatsbuergerschaft-field';
import { GenericModel } from '@/shared/model/generic-model';
import { StatistikAustriaField } from '@/modules/statistik-austria/statistik-austria-field';
import Permissions from '@/security/permissions';
import Storage from '@/security/storage';

import { MandantField } from '@/modules/mandant/mandant-field';
import { VertragsartField } from '@/modules/vertragsart/vertragsart-field';
import BooleanField from '@/shared/fields/boolean-field';
import MoneyField from '@/shared/fields/money-field';
import { FakultaetField } from '@/modules/fakultaet/fakultaet-field';
import DateRangeField from '@/shared/fields/date-range-field';
import { FunktionField } from '@/modules/funktion/funktion-field';

function label(name) {
  return i18n(`entities.mitarbeiter.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.mitarbeiter.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  mitarbeiterNr: new StringField('mitarbeiterNr', label('mitarbeiterNr'), {
    required: false,
    max: 50,
  }),
  anrede: new EnumeratorField(
    'anrede',
    label('anrede'),
    [
      {
        id: 'Herr',
        label: enumeratorLabel('anrede', 'herr'),
      },
      {
        id: 'Frau',
        label: enumeratorLabel('anrede', 'frau'),
      },
    ],
    {
      required: true,
    },
  ),
  titel: new StringField('titel', label('titel'), {
    required: false,
    max: 50,
  }),
  nachname: new StringField('nachname', label('nachname'), {
    required: true,
    max: 100,
  }),
  vorname: new StringField('vorname', label('vorname'), {
    required: true,
    max: 100,
  }),
  email: new StringField('email', label('email'), {
    required: true,
    matches:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    max: 254,
  }),
  vollname: new StringField('vollname', label('vollname')),
  strasse: new StringField('strasse', label('strasse'), {
    required: true,
    max: 255,
  }),
  plz: new StringField('plz', label('plz'), {
    required: false,
    max: 10,
  }),
  stadt: new StringField('stadt', label('stadt'), {
    required: false,
    max: 50,
  }),
  svNummer: new StringField('svNummer', label('svNummer'), {
    required: true,
    matches: /^[0-9]{4}/,
    max: 20,
  }),
  geburtsdatum: new DateField('geburtsdatum', label('geburtsdatum'), {
    required: true,
  }),
  telefon: new StringField('telefon', label('telefon'), {
    required: false,
    max: 255,
  }),
  staatsbuergerschaft: new StaatsbuergerschaftField.relationToOne(
    'staatsbuergerschaft',
    label('staatsbuergerschaft'),
    {
      required: true,
    },
  ),
  urlaubstage: new IntegerField('urlaubstage', label('urlaubstage'), {
    scale: 0,
    min: 0,
    max: 1000,
    required: false,
  }),
  arbeitstage: new IntegerField('arbeitstage', label('arbeitstage'), {
    scale: 0,
    min: 0,
    max: 7,
    required: false,
  }),
  saPers: new StringField('saPers', label('saPers'), {
    required: false,
    max: 255,
  }),
  saGebj: new StringField('saGebj', label('saGebj'), {
    required: false,
    max: 4,
  }),
  saStudienrichtung: new StringField(
    'saStudienrichtung',
    label('saStudienrichtung'),
    {
      required: false,
      max: 255,
    },
  ),
  saFte: new StringField('saFte', label('saFte'), {
    required: false,
    max: 255,
  }),
  sa_sex: StatistikAustriaField.relationToOne('sa_sex', label('sa_sex'), {
    required: false,
  }),
  sa_ausb: StatistikAustriaField.relationToOne('sa_ausb', label('sa_ausb'), {
    required: false,
  }),

  sa_fkt: StatistikAustriaField.relationToOne('sa_fkt', label('sa_fkt'), {
    required: false,
  }),
  sa_taet1: StatistikAustriaField.relationToOne('sa_taet1', label('sa_taet1'), {
    required: false,
  }),
  sa_taet2: StatistikAustriaField.relationToOne('sa_taet2', label('sa_taet2'), {
    required: false,
  }),
  sa_verw: StatistikAustriaField.relationToOne('sa_verw', label('sa_verw'), {
    required: true,
  }),

  funktion: FunktionField.relationToOne('funktion', label('funktion'), {
    required: false,
  }),

  iban: new StringField('iban', label('iban'), {
    required: true,
    matches:
      /^(?:(?:CR|DE|ME|RS|VA)\d{20}|(?:CZ|ES|SE|SK|TN)\d{22}|(?:DK|FI|FO|GL|SD)\d{16}|(?:AT|BA|EE|LT|XK)\d{18}|(?:AE|IL|TL)\d{21}|(?:LY|PT|ST)\d{23}|(?:IT|SM)\d{2}[A-Z]\d{10}[A-Za-z0-9]{12}|(?:HU|PL)\d{26}|(?:AL|CY)\d{10}[A-Za-z0-9]{16}|(?:CH|LI)\d{7}[A-Za-z0-9]{12}|(?:FR|MC)\d{12}[A-Za-z0-9]{11}\d{2}|(?:GB|IE)\d{2}[A-Z]{4}\d{14}|(?:KZ|LU)\d{5}[A-Za-z0-9]{13}|(?:GI|IQ)\d{2}[A-Z]{4}[A-Za-z0-9]{15}|(?:PK|RO)\d{2}[A-Z]{4}[A-Za-z0-9]{16}|(?:PS|QA)\d{2}[A-Z]{4}[A-Za-z0-9]{21}|AD\d{10}[A-Za-z0-9]{12}|AZ\d{2}[A-Z]{4}[A-Za-z0-9]{20}|BE\d{14}|BG\d{2}[A-Z]{4}\d{6}[A-Za-z0-9]{8}|BH\d{2}[A-Z]{4}[A-Za-z0-9]{14}|BR\d{25}[A-Z][A-Za-z0-9]|BY\d{2}[A-Za-z0-9]{4}\d{4}[A-Za-z0-9]{16}|DO\d{2}[A-Za-z0-9]{4}\d{20}|EG\d{27}|GE\d{2}[A-Z]\d{16}|GT\d{2}[A-Za-z0-9]{24}|GR\d{9}[A-Za-z0-9]{16}|HR\d{19}|IS\d{24}|JO\d{2}[A-Z]{4}\d{4}[A-Za-z0-9]{18}|KW\d{2}[A-Z]{4}[A-Za-z0-9]{22}|LC\d{2}[A-Z]{4}[A-Za-z0-9]{24}|LB\d{6}[A-Za-z0-9]{20}|LV\d{2}[A-Z]{4}\d{13}|MD\d{2}[A-Za-z0-9]{20}|MK\d{5}[A-Za-z0-9]{10}\d{2}|MR\d{25}|MT\d{2}[A-Z]{4}\d{5}[A-Za-z0-9]{18}|MU\d{2}[A-Z]{4}\d{19}[A-Z]{3}|NL\d{2}[A-Z]{4}\d{10}|NO\d{13}|SA\d{4}[A-Za-z0-9]{18}|SC\d{2}[A-Z]{4}\d{20}[A-Z]{3}|SI\d{17}|SV\d{2}[A-Z]{4}\d{20}|TR\d{8}[A-Za-z0-9]{16}|UA\d{8}[A-Za-z0-9]{19}|VG\d{2}[A-Z]{4}\d{16}|GE\d{2}[A-Z]{2}\d{16})$/,
    max: 50,
  }),
  bic: new StringField('bic', label('bic'), {
    required: true,
    max: 20,
  }),
  aufenthaltstitelVon: new DateField(
    'aufenthaltstitelVon',
    label('aufenthaltstitelVon'),
  ),
  aufenthaltstitelBis: new DateField(
    'aufenthaltstitelBis',
    label('aufenthaltstitelBis'),
  ),
  aufenthaltstitelArt: new StringField(
    'aufenthaltstitelArt',
    label('aufenthaltstitelArt'),
    {
      required: false,
      max: 255,
    },
  ),
  persoenlicheData: new FilesField(
    'persoenlicheData',
    label('persoenlicheData'),
    Storage.values.mitarbeiterPersoenlicheData,
    Permissions.getFilePermissions('mitarbeiterFileFields', 'persoenlicheData'),
    {
      required: false,
      max: 10 * 1024 * 1024,
      formats: ['pdf', 'doc', 'docx'],
    },
  ),
  mitteilung: new FilesField(
    'mitteilung',
    label('mitteilung'),
    Storage.values.mitarbeiterMitteilung,
    Permissions.getFilePermissions('mitarbeiterFileFields', 'mitteilung'),
    {
      required: false,
      max: 10 * 1024 * 1024,
      formats: ['pdf', 'doc', 'docx'],
    },
  ),
  karenzDoku: new FilesField(
    'karenzDoku',
    label('karenzDoku'),
    Storage.values.mitarbeiterKarenz,
    Permissions.getFilePermissions('mitarbeiterFileFields', 'karenzDoku'),
    {
      required: false,
      max: 10 * 1024 * 1024,
      formats: ['pdf', 'doc', 'docx'],
    },
  ),
  krankmeldung: new FilesField(
    'krankmeldung',
    label('krankmeldung'),
    Storage.values.mitarbeiterKrankmeldung,
    Permissions.getFilePermissions('mitarbeiterFileFields', 'krankmeldung'),
    {
      required: false,
      max: 10 * 1024 * 1024,
      formats: ['pdf', 'doc', 'docx'],
    },
  ),
  grundgehaltGesamtbrutto: new MoneyField(
    'grundgehaltGesamtbrutto',
    label('grundgehaltGesamtbrutto'),
    {
      scale: 2,
      min: 0,
      max: 10000000,
      required: false,
    },
  ),
  gesamtbrutto: new MoneyField('gesamtbrutto', label('gesamtbruttoF'), {
    scale: 2,
    min: 0,
    max: 10000000,
    required: false,
  }),
  gesamtwochenstunden: new DecimalField(
    'gesamtwochenstunden',
    label('gesamtwochenstunden'),
    {
      scale: 2,
      min: 0,
      max: 1000,
      required: false,
    },
  ),

  verwendung: StatistikAustriaField.relationToOne(
    'verwendung',
    label('verwendung'),
    {},
  ),
  vertragsart: VertragsartField.relationToOne(
    'vertragsart',
    label('vertragsart'),
    {},
  ),
  eintrittsdatum: new DateField('eintrittsdatum', label('eintrittsdatum')),
  austrittsdatum: new DateField('austrittsdatum', label('austrittsdatum')),
  gehaltProJahr: new EnumeratorField('gehaltProJahr', label('gehaltProJahr'), [
    {
      id: '12',
      label: enumeratorLabel('gehaltProJahr', '12'),
    },
    {
      id: '14',
      label: enumeratorLabel('gehaltProJahr', '14'),
    },
  ]),
  karenzvertretung: new BooleanField(
    'karenzvertretung',
    label('karenzvertretung'),
  ),

  prozent: new IntegerField('prozent', label('prozent'), {
    scale: 2,
    min: 0,
    max: 100,
    required: false,
  }),
  von: new DateField('von', label('von')),
  bis: new DateField('bis', label('bis')),
  wochenstunden: new DecimalField('wochenstunden', label('wochenstunden'), {
    scale: 2,
    min: 0,
    max: 1000,
    required: false,
  }),
  brutto: new DecimalField('brutto', label('brutto'), {
    scale: 2,
    min: 0,
    max: 10000000,
    required: false,
  }),
  mandant: MandantField.relationToOne('mandant', label('mandant'), {}),
  mandantFilter: MandantField.relationToMany('mandant', label('mandant'), {}),
  kostenstelle: KostenstelleField.relationToMany(
    'kostenstelle',
    label('kostenstelle'),
    {},
  ),
  fakultaet: FakultaetField.relationToMany('fakultaet', label('fakultaet'), {}),
  verwendungKostenstelle: VerwendungKostenstelleField.relationToMany(
    'verwendungKostenstelle',
    label('verwendungKostenstelle'),
    {},
  ),
  ohneKostenstelle: new BooleanField(
    'ohneKostenstelle',
    label('ohneKostenstelle'),
  ),
  onlyInactive: new BooleanField('onlyInactive', label('onlyInactive')),
  onlyInKarenz: new BooleanField('onlyInKarenz', label('onlyInKarenz')),
  onlyInPlan: new BooleanField('onlyInPlan', label('onlyInPlan')),
  status: new EnumeratorField(
    'status',
    label('status'),
    [
      {
        id: 'plan',
        label: enumeratorLabel('status', 'plan'),
      },
      {
        id: 'active',
        label: enumeratorLabel('status', 'active'),
      },
      {
        id: 'inactive',
        label: enumeratorLabel('status', 'inactive'),
      },
      {
        id: 'ruhezeit',
        label: enumeratorLabel('status', 'ruhezeit'),
      },
      {
        id: 'mutterschutz',
        label: enumeratorLabel('status', 'mutterschutz'),
      },
      {
        id: 'mutterkarenz',
        label: enumeratorLabel('status', 'mutterkarenz'),
      },
      {
        id: 'bildungskarenz',
        label: enumeratorLabel('status', 'bildungskarenz'),
      },
      {
        id: 'bildungsteilzeit',
        label: enumeratorLabel('status', 'bildungsteilzeit'),
      },
      {
        id: 'elternteilzeit',
        label: enumeratorLabel('status', 'elternteilzeit'),
      },
      {
        id: 'papamonat',
        label: enumeratorLabel('status', 'papamonat'),
      },
    ],
    {},
  ),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateRangeField('createdAtRange', label('createdAtRange')),
  updatedAtRange: new DateRangeField('updatedAtRange', label('updatedAtRange')),
};

export class MitarbeiterModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
