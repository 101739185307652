import { MitarbeiterService } from '@/modules/mitarbeiter/mitarbeiter-service';
import mitarbeiterListExporterFields from '@/modules/mitarbeiter/mitarbeiter-list-exporter-fields';
import mitarbeiterListExporterWinlineFields from '@/modules/mitarbeiter/mitarbeiter-list-exporter-winline-fields';
import Errors from '@/shared/error/errors';
import Exporter from '@/shared/exporter/exporter';

const INITIAL_PAGE_SIZE = 10;

export default {
  namespaced: true,

  state: {
    rows: [],
    count: 0,
    loading: false,
    exportLoading: false,
    filter: {},
    pagination: {},
    sorter: {},
    table: null,
    selectedRows: [],
  },

  getters: {
    loading: (state) => state.loading,
    exportLoading: (state) => state.exportLoading,
    rows: (state) => state.rows || [],
    count: (state) => state.count,
    hasRows: (state, getters) => getters.count > 0,
    orderBy: (state) => {
      const sorter = state.sorter;
      if (!sorter) {
        return null;
      }
      if (!sorter.prop) {
        return null;
      }
      let direction = sorter.order === 'descending' ? 'DESC' : 'ASC';
      return `${sorter.prop}_${direction}`;
    },
    filter: (state) => state.filter,
    limit: (state) => {
      const pagination = state.pagination;
      if (!pagination || !pagination.pageSize) {
        return INITIAL_PAGE_SIZE;
      }
      return pagination.pageSize;
    },
    offset: (state) => {
      const pagination = state.pagination;
      if (!pagination || !pagination.pageSize) {
        return 0;
      }
      const currentPage = pagination.currentPage || 1;
      return (currentPage - 1) * pagination.pageSize;
    },
    pagination: (state, getters) => {
      return {
        ...state.pagination,
        total: getters.count,
        showSizeChanger: true,
      };
    },
    selectedRows: (state) => {
      return state.selectedRows || [];
    },
  },

  mutations: {
    RESETED(state) {
      state.rows = [];
      state.count = 0;
      state.loading = false;
      state.filter = {};
      state.pagination = {};
      state.sorter = {};
      if (state.selectedRows) {
        state.selectedRows = [];
      }
    },
    UNSELECT_ALL(state) {
      if (state.selectedRows) {
        state.selectedRows = [];
      }
    },
    SELECTED_ROWS_SET(state, payload) {
      state.selectedRows = payload;
    },
    PAGINATION_CHANGED(state, payload) {
      state.pagination = payload || {};
    },
    PAGINATION_CURRENT_PAGE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: payload || 1,
        pageSize: previousPagination.pageSize || INITIAL_PAGE_SIZE,
      };
    },
    PAGINATION_PAGE_SIZE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: previousPagination.currentPage || 1,
        pageSize: payload || INITIAL_PAGE_SIZE,
      };
    },
    SORTER_CHANGED(state, payload) {
      state.sorter = payload || {};
    },
    FETCH_STARTED(state, payload) {
      state.loading = true;

      if (state.table) {
        state.table.clearSelection();
      }

      state.filter = payload && payload.filter ? payload.filter : {};
      state.pagination =
        payload && payload.keepPagination ? state.pagination : {};
    },
    FETCH_SUCCESS(state, payload) {
      state.loading = false;
      state.rows = payload.rows;
      state.count = payload.count;
    },
    FETCH_ERROR(state) {
      state.loading = false;
      state.rows = [];
      state.count = 0;
    },
    EXPORT_STARTED(state) {
      state.exportLoading = true;
    },
    EXPORT_SUCCESS(state) {
      state.exportLoading = false;
    },
    EXPORT_ERROR(state) {
      state.exportLoading = false;
    },
    UPDATE_FILTER(state, payload) {
      state.filter = payload || {};
    },
  },

  actions: {
    doUnselectAll({ commit }) {
      commit('UNSELECT_ALL');
    },
    doSetSelectedRows({ commit }, rows) {
      commit('SELECTED_ROWS_SET', rows);
    },
    async doReset({ commit, dispatch }) {
      commit('RESETED');
      return dispatch('doFetch');
    },
    doChangePagination({ commit, getters, dispatch }, pagination) {
      commit('PAGINATION_CHANGED', pagination);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },
    doChangePaginationPageSize({ commit, getters, dispatch }, pageSize) {
      commit('PAGINATION_PAGE_SIZE_CHANGED', pageSize);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },
    doChangePaginationCurrentPage({ commit, getters, dispatch }, currentPage) {
      commit('PAGINATION_CURRENT_PAGE_CHANGED', currentPage);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },
    doChangeSort({ commit, getters, dispatch }, sorter) {
      commit('SORTER_CHANGED', sorter);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },

    async doExport({ commit, getters }) {
      try {
        if (
          !mitarbeiterListExporterFields ||
          !mitarbeiterListExporterFields.length
        ) {
          throw new Error('userListExporterFields is required');
        }
        commit('EXPORT_STARTED');
        let filter = getters.filter;
        if (getters.selectedRows.length > 0) {
          filter = {};
          filter.id = getters.selectedRows.map((f) => f.id);
        }

        if (getters.filter.onlyActiveKst) {
          filter.onlyActiveKst = getters.filter.onlyActiveKst;
        }

        const response = await MitarbeiterService.list(
          filter,
          getters.orderBy,
          null,
          null,
          true,
          false,
        );
        new Exporter(
          mitarbeiterListExporterFields,
          'mitarbeiter',
        ).transformAndExportAsExcelFile(response.rows);

        commit('EXPORT_SUCCESS');
      } catch (error) {
        Errors.handle(error);

        commit('EXPORT_ERROR');
      }
    },

    async doExportWinLine({ commit, getters }) {
      try {
        if (
          !mitarbeiterListExporterWinlineFields ||
          !mitarbeiterListExporterWinlineFields.length
        ) {
          throw new Error('userListExporterFields is required');
        }
        commit('EXPORT_STARTED');
        let filter = getters.filter;
        if (getters.selectedRows.length > 0) {
          filter = {};
          filter.id = getters.selectedRows.map((f) => f.id);
        }

        const response = await MitarbeiterService.list(
          filter,
          getters.orderBy,
          null,
          null,
          true,
          true,
        );
        new Exporter(
          mitarbeiterListExporterWinlineFields,
          'mitarbeiter',
        ).transformAndExportAsExcelFile(response.rows);

        commit('EXPORT_SUCCESS');
      } catch (error) {
        Errors.handle(error);

        commit('EXPORT_ERROR');
      }
    },

    async doExportActiveKST({ commit, getters, dispatch }) {
      let filter = getters.filter;
      filter.onlyActiveKst = true;
      commit('UPDATE_FILTER', filter);
      dispatch('doExport');
      filter.onlyActiveKst = undefined;
      commit('UPDATE_FILTER', filter);
    },

    async doFetch({ commit, getters }, { filter, keepPagination } = {}) {
      try {
        commit('FETCH_STARTED', { filter, keepPagination });

        const response = await MitarbeiterService.list(
          filter,
          getters.orderBy,
          getters.limit,
          getters.offset,
        );

        commit('FETCH_SUCCESS', {
          rows: response.rows,
          count: response.count,
        });
      } catch (error) {
        Errors.handle(error);
        commit('FETCH_ERROR');
      }
    },
  },
};
