import { MitarbeiterExportWinLineModel } from '@/modules/mitarbeiter/mitarbeiter-export-winline-model';

const { fields } = MitarbeiterExportWinLineModel;

export default [
  fields.mitarbeiterNr,
  fields.anrede,
  fields.titel,
  fields.vorname,
  fields.nachname,
  fields.titelNach,
  fields.strasse,
  fields.plz,
  fields.stadt,
  fields.lkz,
  fields.saStaat,
  fields.telefon,
  fields.email,
  fields.sa_sex,
  fields.iban,
  fields.bic,
  fields.lkzBank,
  fields.eintrittsdatum,
  fields.austrittsdatum,
  fields.svNummer,
  fields.geburtsdatum,
  fields.inaktiv,
];
