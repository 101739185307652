import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';

const AbmeldegrundListPage = () =>
  import('@/modules/abmeldegrund/components/abmeldegrund-list-page.vue');
const AbmeldegrundFormPage = () =>
  import('@/modules/abmeldegrund/components/abmeldegrund-form-page.vue');

export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'abmeldegrund',
        path: '/abmeldegrund',
        component: AbmeldegrundListPage,
        meta: {
          auth: true,
          permission: Permissions.values.abmeldegrundNavigate,
        },
      },
      {
        name: 'abmeldegrundNew',
        path: '/abmeldegrund/new',
        component: AbmeldegrundFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.abmeldegrundNavigate,
        },
      },
      {
        name: 'abmeldegrundEdit',
        path: '/abmeldegrund/:id/edit',
        component: AbmeldegrundFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.abmeldegrundEdit,
        },
        props: true,
      },
    ],
  },
];
