import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/user/permission-checker';

export class AbmeldegrundPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(currentUser);

    this.read = permissionChecker.match(Permissions.values.abmeldegrundRead);
    this.abmeldegrundAutocomplete = permissionChecker.match(
      Permissions.values.abmeldegrundAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.abmeldegrundCreate,
    );
    this.edit = permissionChecker.match(Permissions.values.abmeldegrundEdit);
    this.destroy = permissionChecker.match(
      Permissions.values.abmeldegrundDestroy,
    );
    this.navigate = permissionChecker.match(
      Permissions.values.abmeldegrundNavigate,
    );
  }
}
