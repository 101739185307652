<template>
  <div>
    <div style="padding-top: 10px; padding-bottom: 60px">
      <el-row>
        <el-card shadow="always" :body-style="{ padding: '10px' }">
          <span>
            <app-i18n code="entities.karenz.name"></app-i18n>
          </span>
          <div class="anstellung">
            <el-row :gutter="20">
              <el-col :span="24" :offset="0">
                <app-karenz-list-table
                  @doEdit="doEdit"
                  @doDelete="doDestroyWithConfirm"
                />
              </el-col>
            </el-row>
          </div>

          <div>
            <app-karenz-form-modal
              :visible="dialogKarenzFormVisible"
              :record="row"
              :mitarbeiter="mitarbeiter"
              v-if="dialogKarenzFormVisible"
              @close="doCloseDlg"
            />
          </div>
          <div class="form-buttons">
            <el-button
              v-if="isCreateButtonVisible"
              v-on:click="dialogKarenzFormVisible = !dialogKarenzFormVisible"
              plain
              type="info"
            >
              <app-i18n code="common.newKarenz"></app-i18n>
            </el-button>
          </div>
        </el-card>
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import KarenzFormModal from '@/modules/karenz/components/karenz-form-modal.vue';
import KarenzListTable from '@/modules/karenz/components/karenz-list-table.vue';
import { KarenzPermissions } from '@/modules/karenz/karenz-permissions';

import { i18n } from '@/i18n';

export default {
  name: 'app-karenz-main',
  components: {
    [KarenzFormModal.name]: KarenzFormModal,
    [KarenzListTable.name]: KarenzListTable,
  },
  data() {
    return {
      row: null,
      dialogKarenzFormVisible: false,
    };
  },
  async mounted() {
    const filter = {
      mitarbeiter: this.mitarbeiter,
    };

    await this.doFetch({
      filter,
    });
  },
  computed: {
    ...mapGetters({
      mitarbeiter: 'mitarbeiter/form/id',
      currentUser: 'auth/currentUser',
    }),
    hasPermissionToCreate() {
      return new KarenzPermissions(this.currentUser).create;
    },
    isCreateButtonVisible() {
      if (this.hasPermissionToCreate && this.mitarbeiter != null) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions({
      doFetch: 'karenz/list/doFetch',
      doDestroy: 'karenz/destroy/doDestroy',
    }),

    async doDelete() {},

    doCloseDlg() {
      this.row = null;
      this.dialogKarenzFormVisible = false;
    },

    async doEdit(row) {
      this.row = row;
      this.dialogKarenzFormVisible = true;
    },

    async doDestroyWithConfirm(id) {
      try {
        await this.$confirm(i18n('common.areYouSure'), i18n('common.confirm'), {
          confirmButtonText: i18n('common.yes'),
          cancelButtonText: i18n('common.no'),
          type: 'warning',
        });

        return this.doDestroy(id);
      } catch (error) {
        // no
      }
    },
  },
};
</script>
