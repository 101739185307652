import mitarbeiterListStore from '@/modules/mitarbeiter/mitarbeiter-list-store';
import mitarbeiterViewStore from '@/modules/mitarbeiter/mitarbeiter-view-store';
import mitarbeiterFormStore from '@/modules/mitarbeiter/mitarbeiter-form-store';
import mitarbeiterDestroyStore from '@/modules/mitarbeiter/mitarbeiter-destroy-store';
import mitarbeiterHistoryStore from '@/modules/mitarbeiter/mitarbeiter-history-list-store';
import mitarbeiterImporterStore from '@/modules/mitarbeiter/mitarbeiter-importer-store';
import mitarbeiterImporterBetriebsumlageStore from '@/modules/mitarbeiter/mitarbeiter-importer-betriebsumlage-store';
import mitarbeiterAnstellungListStore from '@/modules/mitarbeiter/mitarbeiter-anstellung-list-store';

export default {
  namespaced: true,

  modules: {
    destroy: mitarbeiterDestroyStore,
    form: mitarbeiterFormStore,
    list: mitarbeiterListStore,
    view: mitarbeiterViewStore,
    history: mitarbeiterHistoryStore,
    importer: mitarbeiterImporterStore,
    importerBetriebsumlage: mitarbeiterImporterBetriebsumlageStore,
    anstellung: mitarbeiterAnstellungListStore,
  },
};
