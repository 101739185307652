import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import DateField from '@/shared/fields/date-field';
import StringField from '@/shared/fields/string-field';
import BooleanField from '@/shared/fields/boolean-field';
import EnumeratorField from '@/shared/fields/enumerator-field';
import { MandantField } from '@/modules/mandant/mandant-field';
import { MitarbeiterField } from '@/modules/mitarbeiter/mitarbeiter-field';
import { VertragsartField } from '@/modules/vertragsart/vertragsart-field';
import { GenericModel } from '@/shared/model/generic-model';
import { BerufField } from '@/modules/beruf/beruf-field';
import MoneyField from '@/shared/fields/money-field';
import { UserField } from '@/modules/user/user-field';

function label(name) {
  return i18n(`entities.anstellung.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.anstellung.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  mitarbeiter: MitarbeiterField.relationToOne(
    'mitarbeiter',
    label('mitarbeiter'),
    {},
  ),
  mandant: MandantField.relationToOne('mandant', label('mandant'), {
    required: true,
  }),
  probeMonat: new EnumeratorField(
    'probeMonat',
    label('probeMonat'),
    [
      {
        id: 'ja',
        label: enumeratorLabel('probeMonat', 'ja'),
      },
      {
        id: 'nein',
        label: enumeratorLabel('probeMonat', 'nein'),
      },
    ],
    {
      required: true,
    },
  ),
  vertragsart: VertragsartField.relationToOne(
    'vertragsart',
    label('vertragsart'),
    {
      required: false,
    },
  ),
  beruf: BerufField.relationToOne('beruf', label('beruf'), {
    required: true,
  }),
  eintrittsdatum: new DateField('eintrittsdatum', label('eintrittsdatum'), {
    required: true,
  }),
  austrittsdatum: new DateField('austrittsdatum', label('austrittsdatum')),
  umreihungsdatum: new DateField('umreihungsdatum', label('umreihungsdatum')),
  dienstjahre: new StringField('dienstjahre', label('dienstjahre'), {
    required: false,
  }),
  angerechneteDienstjahre: new StringField(
    'angerechneteDienstjahre',
    label('angerechneteDienstjahre'),
    {
      required: false,
    },
  ),
  gehaltProJahr: new EnumeratorField(
    'gehaltProJahr',
    label('gehaltProJahr'),
    [
      {
        id: '12',
        label: enumeratorLabel('gehaltProJahr', '12'),
      },
      {
        id: '14',
        label: enumeratorLabel('gehaltProJahr', '14'),
      },
    ],
    {
      required: true,
    },
  ),
  gesamtbrutto: new MoneyField('gesamtbrutto', label('gesamtbrutto'), {
    scale: 2,
    min: 0,
    max: 10000000,
    required: false,
  }),
  gesamtkosten: new MoneyField('gesamtkosten', label('gesamtkosten'), {
    scale: 2,
    min: 0,
    max: 10000000,
    required: false,
  }),
  gesamtwochenstunden: new MoneyField(
    'gesamtwochenstunden',
    label('gesamtwochenstunden'),
    {
      scale: 2,
      min: 0,
      max: 1000,
      required: false,
    },
  ),
  uBahn: new BooleanField('uBahn', label('uBahn')),
  kommunalsteuer: new BooleanField('kommunalsteuer', label('kommunalsteuer'), {
    defaultValue: true,
  }),
  betriebsumlage: new BooleanField('betriebsumlage', label('betriebsumlage'), {
    defaultValue: false,
  }),
  isFiktive: new BooleanField('isFiktive', label('isFiktive')),
  /* karenzvertretung: new BooleanField(
    'karenzvertretung',
    label('karenzvertretung'),
  ), */
  change: new StringField('change', label('change'), {
    required: false,
    max: 254,
  }),
  isActive: new BooleanField('isActive'),
  isChange: new BooleanField('isChange'),
  isHistory: new BooleanField('isHistory'),
  karenzvertretungType: new EnumeratorField(
    'karenzvertretungType',
    label('karenzvertretungType'),
    [
      {
        id: 'karenzvertretung',
        label: enumeratorLabel('karenzvertretungType', 'karenzvertretung'),
      },
      {
        id: 'karenzvertretungFuer',
        label: enumeratorLabel('karenzvertretungType', 'karenzvertretungFuer'),
      },
    ],
    {},
  ),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  updatedBy: UserField.relationToOne('updatedBy', label('updatedBy'), {}),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),
};

export class MitarbeiterAnstellungModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
