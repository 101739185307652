<template>
  <div>
    <el-form
      label-position="top"
      :label-width="labelWidthForm"
      :model="model"
      :rules="rules"
      class="form"
      ref="form"
      v-if="model"
      :disabled="isDisabledInternal"
    >
      <el-card shadow="always" :body-style="{ padding: '20px' }">
        <el-row type="flex" :gutter="20">
          <el-col :span="3">
            <el-form-item
              :label="fields.mandant.label"
              :prop="fields.mandant.name"
              :required="fields.mandant.required"
            >
              <app-mandant-autocomplete-input
                :fetchFn="fields.mandant.fetchFn"
                :mapperFn="fields.mandant.mapperFn"
                mode="single"
                v-model="model[fields.mandant.name]"
                :disabled="!!!!id"
              ></app-mandant-autocomplete-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item
              :label="fields.beruf.label"
              :prop="fields.beruf.name"
              :required="fields.beruf.required"
            >
              <app-beruf-autocomplete-input
                :fetchFn="fields.beruf.fetchFn"
                :mapperFn="fields.beruf.mapperFn"
                :showCreate="true"
                mode="single"
                v-model="model[fields.beruf.name]"
              ></app-beruf-autocomplete-input>
            </el-form-item>
          </el-col>
          <el-col :span="4" :offset="6">
            <el-form-item label=" " prop=" " v-if="!!status">
              <el-tag :type="getStatusTagType(status.id)">
                <b>{{ status.label }}</b>
              </el-tag>
            </el-form-item>
          </el-col>
          <el-col :span="3" :offset="0">
            <el-form-item
              :label="fields.gesamtwochenstunden.label"
              :prop="fields.gesamtwochenstunden.name"
              :required="fields.gesamtwochenstunden.required"
            >
              <div style="font-weight: bold">
                <app-money-input
                  v-model="model[fields.gesamtwochenstunden.name]"
                  :disabled="true"
                ></app-money-input>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item
              :label="fields.gesamtbrutto.label"
              :prop="fields.gesamtbrutto.name"
              :required="fields.gesamtbrutto.required"
            >
              <app-money-input
                v-model="model[fields.gesamtbrutto.name]"
                :disabled="true"
              ></app-money-input>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item
              :label="fields.gesamtkosten.label"
              :prop="fields.gesamtkosten.name"
              :required="fields.gesamtkosten.required"
            >
              <app-money-input
                v-model="model[fields.gesamtkosten.name]"
                :disabled="true"
              ></app-money-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" :gutter="20">
          <el-col :span="4">
            <el-form-item
              :label="fields.vertragsart.label"
              :prop="fields.vertragsart.name"
              :required="fields.vertragsart.required"
            >
              <app-vertragsart-autocomplete-input
                :fetchFn="fields.vertragsart.fetchFn"
                :mapperFn="fields.vertragsart.mapperFn"
                mode="single"
                v-model="model[fields.vertragsart.name]"
              ></app-vertragsart-autocomplete-input>
            </el-form-item>
          </el-col>

          <el-col :span="3">
            <el-form-item
              :label="fields.eintrittsdatum.label"
              :prop="fields.eintrittsdatum.name"
              :required="fields.eintrittsdatum.required"
            >
              <el-date-picker
                :format="elementUiDateFormat"
                placeholder
                type="date"
                v-model="model[fields.eintrittsdatum.name]"
              ></el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span="3">
            <el-form-item
              :label="fields.austrittsdatum.label"
              :prop="fields.austrittsdatum.name"
              :required="fields.austrittsdatum.required"
            >
              <el-date-picker
                :format="elementUiDateFormat"
                placeholder
                type="date"
                v-model="model[fields.austrittsdatum.name]"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item
              :label="fields.dienstjahre.label"
              :prop="fields.dienstjahre.name"
              :required="fields.dienstjahre.required"
            >
              <el-input
                :readonly="true"
                v-model="model[fields.dienstjahre.name]"
                tabindex="-1"
                :disabled="true"
              />
            </el-form-item>
          </el-col>

          <el-col :span="1" :offset="1">
            <el-form-item
              label=" "
              :prop="fields.uBahn.name"
              :required="fields.uBahn.required"
            >
              <el-checkbox
                v-model="model[fields.uBahn.name]"
                @change="recalculateGesamt"
              >
                {{ fields.uBahn.label }}
              </el-checkbox>
            </el-form-item>
          </el-col>
          <el-col :span="1" :offset="1">
            <el-form-item
              label=" "
              :prop="fields.kommunalsteuer.name"
              :required="fields.kommunalsteuer.required"
            >
              <el-checkbox
                v-model="model[fields.kommunalsteuer.name]"
                @change="recalculateGesamt"
              >
                {{ fields.kommunalsteuer.label }}
              </el-checkbox>
            </el-form-item>
          </el-col>
          <el-col :span="1" :offset="1">
            <el-form-item
              label=" "
              :prop="fields.betriebsumlage.name"
              :required="fields.betriebsumlage.required"
            >
              <el-checkbox
                v-model="model[fields.betriebsumlage.name]"
                @change="recalculateGesamt"
              >
                {{ fields.betriebsumlage.label }}
              </el-checkbox>
            </el-form-item>
          </el-col>

          <el-col :span="3" :offset="1">
            <el-form-item
              :label="fields.probeMonat.label"
              :prop="fields.probeMonat.name"
              :required="fields.probeMonat.required"
            >
              <el-select placeholder v-model="model[fields.probeMonat.name]">
                <el-option :value="undefined">--</el-option>
                <el-option
                  :key="option.id"
                  :label="option.label"
                  :value="option.id"
                  v-for="option in fields.probeMonat.options"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item
              :label="fields.gehaltProJahr.label"
              :prop="fields.gehaltProJahr.name"
              :required="fields.gehaltProJahr.required"
            >
              <el-select placeholder v-model="model[fields.gehaltProJahr.name]">
                <el-option :value="undefined">--</el-option>
                <el-option
                  :key="option.id"
                  :label="option.label"
                  :value="option.id"
                  v-for="option in fields.gehaltProJahr.options"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" :gutter="20">
          <el-col :span="3">
            <el-form-item
              :label="fields.dienstjahre.label"
              :prop="fields.dienstjahre.name"
              :required="fields.dienstjahre.required"
            >
              <el-input
                :readonly="true"
                v-model="model[fields.dienstjahre.name]"
                tabindex="-1"
              />
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item
              :label="fields.angerechneteDienstjahre.label"
              :prop="fields.angerechneteDienstjahre.name"
              :required="fields.angerechneteDienstjahre.required"
            >
              <el-input
                :readonly="true"
                v-model="model[fields.angerechneteDienstjahre.name]"
                tabindex="-1"
              />
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item
              :label="fields.umreihungsdatum.label"
              :prop="fields.umreihungsdatum.name"
              :required="fields.umreihungsdatum.required"
            >
              <el-date-picker
                :format="elementUiDateFormat"
                placeholder
                type="date"
                v-model="model[fields.umreihungsdatum.name]"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>

      <el-row>
        <div style="padding-top: 10px">
          <el-card shadow="always" :body-style="{ padding: '10px' }">
            <span>
              <app-i18n code="entities.grundgehalt.name"></app-i18n>
            </span>
            <app-grundgehalt-component
              ref="grundgehalt"
              :record="grundgehalt"
              :anstellung="id"
              :modal="modal"
              :gesamtbrutto="model[fields.gesamtbrutto.name]"
              :gesamtkosten="model[fields.gesamtkosten.name]"
              @woStdChange="model[fields.gesamtwochenstunden.name] = $event"
              :hasPermissionToDeleteKostenstelle="
                hasPermissionToDeleteKostenstelle
              "
              :hasPermissionToEditKostenstelle="hasPermissionToEditKostenstelle"
              :hasPermissionToCreateKostenstelle="
                hasPermissionToCreateKostenstelle
              "
              :isDisabled="isDisabledInternal"
            ></app-grundgehalt-component>
          </el-card>
        </div>
      </el-row>
      <el-row>
        <div style="padding-top: 10px">
          <el-card shadow="always" :body-style="{ padding: '10px' }">
            <span>
              <app-i18n code="entities.gehaltszulage.name"></app-i18n>
            </span>
            <app-gehaltszulage-component
              ref="gehaltszulage"
              :record="gehaltszulage"
              :anstellung="id"
              :modal="modal"
              :gesamtbrutto="model[fields.gesamtbrutto.name]"
              :gesamtkosten="model[fields.gesamtkosten.name]"
              :hasPermissionToDeleteKostenstelle="
                hasPermissionToDeleteKostenstelle
              "
              :hasPermissionToEditKostenstelle="hasPermissionToEditKostenstelle"
              :hasPermissionToCreateKostenstelle="
                hasPermissionToCreateKostenstelle
              "
              :isDisabled="isDisabledInternal"
            ></app-gehaltszulage-component>
          </el-card>
        </div>
      </el-row>
      <app-karenz-main
        ref="karenz"
        :modal="modal"
        :isDisabled="isDisabledInternal"
      ></app-karenz-main>
    </el-form>
  </div>
</template>
<script>
import { i18n } from '@/i18n';
import { mapGetters, mapActions } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { MitarbeiterAnstellungModel } from '@/modules/mitarbeiter/mitarbeiter-anstellung-model';
import { MitarbeiterPermissions } from '@/modules/mitarbeiter/mitarbeiter-permissions';
import GrundgehaltComponent from '@/modules/grundgehalt/components/grundgehalt-list.vue';
import GehaltszulageComponent from '@/modules/gehaltszulage/components/gehaltszulage-list.vue';
import KarenzMainComponent from '@/modules/karenz/components/karenz-main.vue';
import CalculationHelper from '@/shared/helpers/calculationHelper';
/* import iban from 'iban'; */
const { fields } = MitarbeiterAnstellungModel;
const formSchema = new FormSchema([
  fields.id,
  fields.mitarbeiter,
  fields.mandant,
  fields.probeMonat,
  fields.vertragsart,
  fields.beruf,
  fields.eintrittsdatum,
  fields.austrittsdatum,
  fields.umreihungsdatum,
  fields.dienstjahre,
  fields.angerechneteDienstjahre,
  fields.gehaltProJahr,
  fields.gesamtbrutto,
  fields.gesamtkosten,
  fields.gesamtwochenstunden,
  fields.uBahn,
  fields.kommunalsteuer,
  fields.betriebsumlage,
  fields.isActive,
  fields.isChange,
  fields.isHistory,
]);
export default {
  name: 'app-mitarbeiter-anstellung-form',
  props: ['record', 'formValidationSuccess', 'modal', 'saveLoading', 'status'],
  components: {
    [GrundgehaltComponent.name]: GrundgehaltComponent,
    [GehaltszulageComponent.name]: GehaltszulageComponent,
    [KarenzMainComponent.name]: KarenzMainComponent,
  },
  data() {
    return {
      rules: formSchema.rules(),
      model: {},
      grundgehalt: [],
      gehaltszulage: [],
      isLoaded: false,
      gesamtkosten: 0,
    };
  },
  async created() {
    if (!this.modal) {
      this.doReset();
    }
    this.model = formSchema.initialValues(this.record || {});
    this.grundgehalt = this.record.grundgehalt;
    this.gehaltszulage = this.record.gehaltszulage;
    if (!this.modal) {
      this.doSetAnstellung(this.model);
    }
  },
  mounted() {
    this.isLoaded = true;
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      elementUiDateFormat: 'layout/elementUiDateFormat',
      anstellung: 'mitarbeiter/form/anstellung',
      isFormDisabled: 'mitarbeiter/form/isFormDisabled',
      viewMode: 'mitarbeiter/form/viewMode',
      age: 'mitarbeiter/form/age',
      eintrittsdatum: 'mitarbeiter/form/eintrittsdatum',
      gesamtbruttoGrundgehalt: 'mitarbeiter/anstellung/gesamtbruttoGrundgehalt',
      gesamtbruttoGehaltszulage:
        'mitarbeiter/anstellung/gesamtbruttoGehaltszulage',
      recalculateGesamtkosten: 'mitarbeiter/anstellung/recalculateGesamtkosten',
      reActivationStarted: 'mitarbeiter/form/reActivationStarted',
      svWerteActive: 'settings/svWerteActive',
    }),
    fields() {
      return fields;
    },
    recalculateGesamtkostenChanged() {
      return this.recalculateGesamtkosten;
    },
    id() {
      return this.record.id;
    },
    hasPermissionToRemoveKarenz() {
      return new MitarbeiterPermissions(this.currentUser).removeKarenz;
    },
    hasPermissionToChangeMandant() {
      return new MitarbeiterPermissions(this.currentUser).destroy;
    },
    hasPermissionToCreateKostenstelle() {
      return new MitarbeiterPermissions(this.currentUser).createKostenstelle;
    },
    hasPermissionToEditKostenstelle() {
      return new MitarbeiterPermissions(this.currentUser).editKostenstelle;
    },
    hasPermissionToDeleteKostenstelle() {
      return new MitarbeiterPermissions(this.currentUser).destroyKostenstelle;
    },
    isDisabledInternal() {
      return this.isFormDisabled || !!this.modal;
    },
  },
  watch: {
    model: {
      deep: true,
      handler: async function () {
        if (this.isLoaded && !this.modal) {
          await this.$store.dispatch(
            'mitarbeiter/form/doSetAnstellung',
            formSchema.cast(this.model),
          );
        }
      },
    },
    'model.vertragsart': {
      handler: function (newVal, oldVal) {
        if (this.isLoaded && !this.modal) {
          if (newVal && newVal.label.toLowerCase() === 'befristet') {
            this.rules.austrittsdatum.push({
              message: 'Austrittsdatum ist ein Pflichtfeld',
              required: true,
            });
          } else if (oldVal && oldVal.label.toLowerCase() === 'befristet') {
            this.rules.austrittsdatum = [];
          }
          //commented out as austrittsdatum is removed/not visible even when the austrittsdatum is set
          /* else if (newVal && newVal.label.toLowerCase() === 'unbefristet') {
            this.rules.austrittsdatum = [];

            this.$refs.form.fields
              .find((f) => f.prop == 'austrittsdatum')
              .resetField();
            this.model.austrittsdatum = null;
          } */
        }
      },
    },
    recalculateGesamtkostenChanged: {
      handler: function (value) {
        if (this.isLoaded && !this.modal) {
          if (value) {
            this.recalculateGesamt();
          }
        }
      },
    },
    gesamtbrutto() {
      return this.gesamtbruttoGrundgehalt + this.gesamtbruttoGehaltszulage;
    },
    reActivationStarted: {
      handler: function (value) {
        if (value) {
          this.model.eintrittsdatum = null;
          this.model.austrittsdatum = null;
          this.model.vertragsart = null;
          this.model.probeMonat = null;
          this.model.gehaltProJahr = null;
        }
      },
    },
  },
  methods: {
    ...mapActions({
      doReset: 'mitarbeiter/anstellung/doReset',
      doSetAnstellung: 'mitarbeiter/anstellung/doSetAnstellung',
      doResetRecalculateSet: 'mitarbeiter/anstellung/doResetRecalculateSet',
    }),
    async recalculateGesamt() {
      if (this.gesamtbruttoGrundgehalt === null) {
        await this.$refs.grundgehalt.recalculateGesamtbrutto();
      }
      if (this.gesamtbruttoGehaltszulage === null) {
        await this.$refs.gehaltszulage.recalculateGesamtbrutto();
      }

      const gesamtbrutto =
        this.gesamtbruttoGrundgehalt + this.gesamtbruttoGehaltszulage;
      const gesamtkosten = new CalculationHelper(
        this.svWerteActive,
      ).gesamtkosten(
        gesamtbrutto,
        this.age,
        this.eintrittsdatum,
        this.model.uBahn,
        this.model.kommunalsteuer,
        this.model.gesamtwochenstunden,
      );
      this.model.gesamtbrutto = gesamtbrutto;
      this.model.gesamtkosten = gesamtkosten;
      this.doResetRecalculateSet();
    },
    doCancel() {
      this.$emit('cancel');
    },
    async validateForm() {
      // eslint-disable-next-line no-useless-catch
      try {
        await this.$refs.grundgehalt.$refs.form.validate();
        await this.$refs.gehaltszulage.$refs.form.validate();
        await this.$refs.form.validate();
      } catch (error) {
        await this.$refs.gehaltszulage.$refs.form.validate();
        await this.$refs.form.validate();
        throw error;
      }
    },

    async doAskForConfirmation(func) {
      try {
        await this.$confirm(i18n('common.areYouSure'), i18n('common.confirm'), {
          confirmButtonText: i18n('common.yes'),
          cancelButtonText: i18n('common.no'),
          type: 'warning',
        });

        return func();
      } catch (error) {
        // no
      }
    },

    getStatusTagType(status) {
      if (status === 'active') {
        return 'success';
      }
      if (status === 'inactive') {
        return 'danger';
      }
      if (status === 'elternteilzeit' || status == 'bildungsteilzeit') {
        return '';
      }
      return 'info';
    },
    i18n(code) {
      return i18n(code);
    },
  },
};
</script>
<style scoped>
.el-form-item {
  margin-bottom: 5px !important;
}
</style>
