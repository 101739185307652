import { i18n } from '@/i18n';
import DateField from '@/shared/fields/date-field';
import { GenericModel } from '@/shared/model/generic-model';
import StringField from '@/shared/fields/string-field';
import EnumeratorField from '@/shared/fields/enumerator-field';

function label(name) {
  return i18n(`entities.mitarbeiterExportWinline.fields.${name}`);
}
function enumeratorLabel(name, value) {
  return i18n(`entities.mitarbeiterExportWinline.enumerators.${name}.${value}`);
}
const fields = {
  mitarbeiterNr: new StringField('mitarbeiterNr', label('mitarbeiterNr'), {
    required: false,
    max: 50,
  }),
  anrede: new EnumeratorField('anrede', label('anrede'), [
    {
      id: 'Herr',
      label: enumeratorLabel('anrede', 'herr'),
    },
    {
      id: 'Frau',
      label: enumeratorLabel('anrede', 'frau'),
    },
  ]),
  titel: new StringField('titel', label('titel'), {
    required: false,
    max: 50,
  }),
  vorname: new StringField('vorname', label('vorname'), {
    required: false,
    max: 100,
  }),
  nachname: new StringField('nachname', label('nachname'), {
    required: false,
    max: 100,
  }),
  titelNach: new StringField('titelNach', label('titelNach'), {
    required: false,
    max: 50,
  }),
  strasse: new StringField('strasse', label('strasse'), {
    required: false,
    max: 255,
  }),
  plz: new StringField('plz', label('plz'), {
    required: false,
    max: 10,
  }),
  stadt: new StringField('stadt', label('stadt'), {
    required: false,
    max: 50,
  }),
  lkz: new StringField('lkz', label('lkz'), {
    required: false,
    max: 50,
  }),
  saStaat: new StringField('saStaat', label('saStaat'), {
    required: false,
    max: 255,
  }),
  /*   staatsangehorigkeit: new StringField(
    'staatsangehorigkeit',
    label('staatsangehorigkeit'),
    {
      required: false,
      max: 255,
    },
  ), */
  telefon: new StringField('telefon', label('telefon'), {
    required: false,
    max: 255,
  }),
  email: new StringField('email', label('email'), {
    required: false,
    matches:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    max: 254,
  }),
  sa_sex: new StringField('sa_sex', label('sa_sex'), {}),
  iban: new StringField('iban', label('iban'), {
    required: false,
    max: 50,
  }),
  bic: new StringField('bic', label('bic'), {
    required: false,
    max: 20,
  }),
  lkzBank: new StringField('lkzBank', label('lkzBank')),
  eintrittsdatum: new DateField('eintrittsdatum', label('eintrittsdatum'), {
    format: 'DD.MM.YYYY',
  }),
  austrittsdatum: new DateField('austrittsdatum', label('austrittsdatum'), {
    format: 'DD.MM.YYYY',
  }),
  svNummer: new StringField('svNummer', label('svNummer'), {
    required: false,
    matches: /^[0-9]{4}/,
    max: 20,
  }),
  geburtsdatum: new DateField('geburtsdatum', label('geburtsdatum'), {
    format: 'DD.MM.YYYY',
  }),
  inaktiv: new DateField('inaktiv', label('inaktiv'), {
    format: 'DD.MM.YYYY',
  }),
};
export class MitarbeiterExportWinLineModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
