<template>
  <div class="app-pdf-view">
    <el-dialog
      :visible.sync="dialogVisible"
      :close-on-click-modal="true"
      :close-on-press-escape="true"
      width="60%"
      top="0"
      destroy-on-close
      :modal="true"
      :append-to-body="true"
    >
      <el-row :gutter="10">
        <el-col :span="20" :offset="0">
          <app-pdf-view v-on:loading="setLoading" :fileId="id"></app-pdf-view>
        </el-col>
        <div>
          <el-col :span="4" :offset="0">
            <el-card class="box-card actions" shadow="never">
              <div slot="header" class="clearfix">
                <span>Aktionen</span>
              </div>
              <div class="clearfix">
                <el-button
                  v-if="hasPermissionToDownload"
                  :disabled="loading"
                  v-on:click="download()"
                  type="text"
                >
                  <app-i18n code="common.download"></app-i18n>
                </el-button>
              </div>
              <div class="clearfix">
                <el-button
                  v-on:click="dialogVisible = false"
                  type="text"
                  :disabled="loading"
                >
                  <app-i18n code="common.cancel"></app-i18n>
                </el-button>
              </div>
            </el-card>
          </el-col>
        </div>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'app-pdf-view-modal',
  props: ['file', 'visible', 'hasPermissionToDownload'],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    id() {
      return this.file.id;
    },
    downloadUrl() {
      return this.file.downloadUrl;
    },
    dialogVisible: {
      get: function () {
        return this.visible;
      },
      set: function (value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    title() {
      return 'Preview'; //i18n('user.new.titleModal');
    },
  },
  methods: {
    download() {
      window.open(this.downloadUrl, '_blank');
    },
    setLoading(value) {
      this.loading = value;
    },
  },
};
</script>

<style scoped>
.text-area {
  padding-top: 20px;
}
</style>
